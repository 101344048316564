import React, { useEffect, useState } from "react";
import { SectionSpacer, SectionWrapper } from "../../components/Common";
import { styled } from "styled-components";
import { colors } from "../../assets";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { OurServicesData } from "../../assets/constants/content";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 500 },
    items: 1,
  },
  smmobile: {
    breakpoint: { max: 499, min: 0 },
    items: 1,
  },
};

const SectonBGContainer = styled.div`
  position: relative;
  background-color: ${colors.grayBgColor};
  padding: 4rem 0;
  padding-top: 0;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40%;
    background-color: white;
  }
`;
const SectionBGWrapper = styled.div`
  height: 50vh;
  /* background-color: ${colors.grayBgColor}; */
`;
const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media screen and (max-width: 500px) {
    justify-content: flex-start;
  }
`;

const HeadingBorderBottom = styled.div`
  border-bottom: 2px solid ${colors.primaryBlueColor};
  width: 4%;

  @media screen and (max-width: 500px) {
    width: 10%;
  }
`;

const MainHeading = styled.div`
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.headColorBlue};
  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;
const SubHeading = styled.div`
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.darkBlueColor};
  margin-bottom: 2rem;

  @media screen and (max-width: 800px) {
    width: 95%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: 28px;
    text-align: start;
  }
`;
const ServiceContainer = styled.div`
  border: 1px solid #eaebf0;
  width: 91%;
  margin: auto;
  background-color: ${colors.whiteColor};
`;
const ServiceWrapper = styled.div`
  position: relative;
  padding-bottom: 3rem;
  z-index: 12;
`;
const ServiceImage = styled.img`
  width: 100%;
  // aspect-ratio: 1/1; 
  // object-fit: cover; 
  /* object-position: top; */
`;
const ServiceHeading = styled.div`
  font-family: Poppins;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.darkBlueColor};
`;
const ServiceDescription = styled.p`
  font-family: Rubik;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  color: ${colors.headColorBlue};
  

`;
const ServiceIcon = styled.div``;
const ServiceText = styled.p`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.darkBlueColor};
`;
const ServiceContainerBottom = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.whiteColor};
  gap: 1rem;
`;
const ServiceBottomWrapper = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

const Break = styled.br`
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const OurServices = () => {
  const [showDots, setShowDots] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setShowDots(window.innerWidth <= 1025);
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <SectonBGContainer>
      {/* <SectionBGWrapper> */}
      <SectionWrapper>
        <SectionSpacer>
          <HeadingWrapper>
            {/* <HeadingBorderBottom />
              <MainHeading>Our featured services</MainHeading>
              <HeadingBorderBottom /> */}
          </HeadingWrapper>
          <SubHeading>خدماتنا</SubHeading>
          <ServiceWrapper>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={1000}
              showDots={showDots}
              swipeable={true}
              renderButtonGroupOutside={false}
              renderDotsOutside
              // customDot={<CustomDot />}
              removeArrowOnDeviceType={[
                "mobile",
                "tablet",
                "desktop",
                "superLargeDesktop",
                "smmobile",
              ]}
            >
              {OurServicesData.map((instance, index) => {
                return (
                  <ServiceContainer className="" key={index}>
                    <ServiceImage
                      src={instance.image}
                      alt="ClothesCollections"
                      className=""
                    ></ServiceImage>
                    <ServiceContainerBottom>
                      {" "}
                      <ServiceHeading>{instance.heading}</ServiceHeading>
                      <ServiceDescription>
                        {instance.description}
                      </ServiceDescription>
                      <ServiceBottomWrapper
                        href={instance.redirection_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ServiceIcon>{instance.icon}</ServiceIcon>
                        <ServiceText>{instance.text}</ServiceText>
                      </ServiceBottomWrapper>
                    </ServiceContainerBottom>
                  </ServiceContainer>
                );
              })}
            </Carousel>
          </ServiceWrapper>
        </SectionSpacer>
      </SectionWrapper>
      {/* </SectionBGWrapper> */}
    </SectonBGContainer>
  );
};
