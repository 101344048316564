import { ReactComponent as FacebookIcon } from "../icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../icons/linkedin.svg";
import { ReactComponent as InstagramIcon } from "../icons/instagram.svg";
import {
  Absher,
  AeroplaneIcon,
  BangladeshFlag,
  ButtonArrow,
  ClientsOne,
  ClientsTwo,
  DollarIcon,
  EgyptFlag,
  EqualIcon,
  EthiopiaFlag,
  FeesIcon,
  HomeService,
  Hrsd,
  IndiaFlag,
  KenyaFlag,
  Mofa,
  MonthlyService,
  MovingService,
  Musand,
  OfficeService,
  OurService1,
  OurService2,
  OurService3,
  PakistanFlag,
  PersonalService,
  PersonalService2,
  PhilippineFlag,
  ReferenceMinistry,
  Slider1,
  Slider2,
  Slider3,
  Slider4,
  SrilankaFlag,
  TheamBlog1,
  TheamBlog2,
  TheamBlog3,
  UgandaFlag,
  WebIcon,
  WorkStep1Icon,
  WorkStep2Icon,
  WorkStep3Icon,
  YamenFlag,
  colors,
} from "..";

export const socials = [
  // {
  //   icon: <FacebookIcon />,
  //   name: "facebook",
  //   url: "https://www.facebook.com",
  // },
  {
    icon: <TwitterIcon />,
    name: "twitter",
    url: "https://www.twitter.com/muhcompany1",
  },
  // {
  //   icon: <LinkedinIcon />,
  //   name: "linkedin",
  //   url: "https://www.linkedin.com",
  // },
  {
    icon: <InstagramIcon />,
    name: "instagram",
    url: "https://www.instagram.com/muhannad.recruitment",
  },
];

export const MenuData = [
  {
    title: "الرئيسية",
    url: "/",
    isExpandable: false,
  },
  {
    title: "من نحن",
    url: "/about",
    isExpandable: false,
  },
  // {
  //   title: "Services",
  //   url: "",
  //   isExpandable: true,
  // },
  // {
  //   title: "Resources",
  //   url: "",
  //   isExpandable: true,
  // },
  {
    title: "التواصل",
    url: "/contact-us",
    isExpandable: false,
  },
];

export const HowWeWorkData = [
  {
    icon: <WorkStep1Icon />,
    number: 1,
    heading: "تصفح الموقع",
    data: "Sed ut perspiciatis unde omnisiste natus error voluptatem",
  },
  {
    icon: <WorkStep2Icon />,
    number: 2,
    heading: "اختر الخدمة المرغوبة",
    data: "Sed ut perspiciatis unde omnisiste natus error voluptatem",
  },
  {
    icon: <WorkStep3Icon />,
    number: 3,
    heading: "تواصل معنا",
    data: "Sed ut perspiciatis unde omnisiste natus error voluptatem",
  },
];

export const OurServicesData = [
  {
    image: Slider1,
    heading: "استقدام العمالة المنزلية",
    description:
      "يمكنك الاستفادة من خدمات استقدام العمالة المنزلية للحصول على العمالة المنزلية التي تحتاجها، سواء كانوا عمالة منزلية أو سائقين، لتلبية احتياجاتك في أعمال المنزل بكفاءة عالية واحترافية.",
    icon: <ButtonArrow />,
    text: "للطلب",
    redirection_link: "https://muhannad.sa/recruitment",
  },
  {
    image: Slider2,
    heading: "عقود العمالة الشهرية",
    description:
      "شركة مهند للاستقدام تقدم عقود عمالة شهرية مرنة ومتنوعة تتضمن العمالة المنزلية والسائقين، مما يسهل عليك الحصول على الخدمات التي تلبي احتياجاتك الشهرية بكل يسر وسهولة بالإضافة إلى ذلك، مع عقد شهري دون احتساب أي رسوم إضافية، ستتمتع بالاستفادة من الخدمات بشكل مريح واقتصادي.",
    icon: <ButtonArrow />,
    text: "للطلب",
    redirection_link: "https://wa.me/966563363544",
  },
  {
    image: Slider3,
    heading: "استقدام عمالة مهنية",
    description:
     "خدمة استقدام العمالة المهنية تمنح الشركات والمؤسسات الفرصة للحصول على عمالة مهنية عالية الكفاءة من خلال شركتنا بسرعة وفاعلية، لضمان تلبية احتياجاتهم ومتطلباتهم بشكل فعال.",
       icon: <ButtonArrow />,
    text: "للطلب",
    redirection_link: "https://wa.me/966563363544",
  },
  {
    image: Slider4,
    heading: "نقل الخدمات",
    description:
      "هي الخدمة التي تُمكن الشركات والمؤسسات من نقل خدمات العمال الوافدين إلى منشآت أخرى بكفاءة وسهولة، مما يضمن استمرارية الأعمال وسيرها بسلاسة.",
    icon: <ButtonArrow />,
    text: "للطلب",
    redirection_link: "https://wa.me/966563363544",
  },
  // {
  //   image: PersonalService,
  //   heading: "Personal Service",
  //   description: "As a app web crawler expert a significance of internet.",
  //   icon: <ButtonArrow />,
  //   text: "للطلب",
  //   redirection_link: "https://wa.me/966563363544",
  // },
];

export const ClientTestimonialsData = [
  {
    discription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    name: "Farhan Rio",
    role: "Agent Manager",
    star: 5,
    image: ClientsOne,
  },
  {
    discription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    name: "Leofar Tom",
    role: "Founder of Ref Group",
    star: 5,
    image: ClientsTwo,
  },
  {
    discription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    name: "Leofar Tom",
    role: "Founder of Ref Group",
    star: 5,
    image: ClientsTwo,
  },
];

export const blogData = [
  {
    image: TheamBlog1,
    date: "17 Aug/23",
    about: "CaseThemes",
    aboutBlog: "Office",
    heading: "How stay calm from the first time.",
    discription:
      "Quality service begins with quality people.Each CleanNet® certified operator receivesextensive, ongoing training in product…",
  },
  {
    image: TheamBlog2,
    date: "15 Aug/23",
    about: "CaseThemes",
    aboutBlog: "Apartment",
    heading: "Our proprietary enables Quality.",
    discription:
      "Quality service begins with quality people.Each CleanNet® certified operator receivesextensive, ongoing training in product…",
  },
  {
    image: TheamBlog3,
    date: "12 Jul/22",
    about: "CaseThemes",
    aboutBlog: "Carpet",
    heading: "Locate Bixol USA Office Near You.",
    discription:
      "Quality service begins with quality people.Each CleanNet® certified operator receivesextensive, ongoing training in product…",
  },
  {
    image: TheamBlog1,
    date: "17 Aug/23",
    about: "CaseThemes",
    aboutBlog: "Office",
    heading: "How stay calm from the first time.",
    discription:
      "Quality service begins with quality people.Each CleanNet® certified operator receivesextensive, ongoing training in product…",
  },
  {
    image: TheamBlog3,
    date: "12 Jul/22",
    about: "CaseThemes",
    aboutBlog: "Carpet",
    heading: "Locate Bixol USA Office Near You.",
    discription:
      "Quality service begins with quality people.Each CleanNet® certified operator receivesextensive, ongoing training in product…",
  },
];

export const faqData = [
  {
    rowOne: [
      {
        title: "متطلبات إصدار التأشيرة للإستقدام",
        answer:
          "يرجى التأكد من دفع الرسوم الحكومية 2000 ريال سعودي لسداد رسوم التأشيرة، من خلال خدمة سداد لدى البنك الخاص بكم قبل طلب التأشيرة. المدفوعات والفواتير > المدفوعات الحكومية الاستقدام > تأشيرة عامل > دفع رقم الهوية – عدد التأشيرات – تأشيرة عمل لتقديم طلب إصدار تأشيرة الإستقدام، يرجى زيارة موقع مساند برنامج العمالة المنزلية لإصدار تأشيرات الإستقدام www.musaned.gov.sa يتطلب عليك تعبئة مهنة العامل المطلوب إصدار التأشيرة له مع تحديد الجنسية وجهة القدوم. كما يتطلب عليك دفع الرسوم التشغيلية 150 ريال سعودي لطلب إصدار التأشيرة بشكل إلكتروني. حسابي > لوحة التحكم طلبات التأشيرات > تأشيرة العمالة المنزلية التعهد والاقرار > القدرة المالية معلومات التأشيرة > التأكيد والدفع",
      },
      {
        title: "متطلبات استقدام العمالة المنزلية",
        answer:
          "يرجى التأكد من اصدار تأشيرة استقدام العمالة المنزلية من خلال موقع مساند ليتم التأكيد والتواصل معنا لاختيار السير الذاتية للعمالة المنزلية من خلال الواتساب.الوثائق المطلوبة لإستقدام العمالة المنزليةارسال التأشيرة + الهوية + العنوان الوطني pdf + الايميل + رقمين للتواصليتطلب عليك رفع طلب التعاقد في موقع مساند مع تحديد الجنسية وجهة القدوم.كما يتطلب عليك دفع رسوم الاستقدام حسب الجنسية والمهنة.حسابي > لوحة التحكمطلبات العقود > التعاقد > حسب المواصفاتاختيار نوع العمل والمهنة والجنسيةبحث واختيار المكتب > ادخال البياناتتأكيد طلب ارسال التعاقد",
      },
      {
        title: "متطلبات استقدام العمالة المنزلية المعينة (معروفة)",
        answer:
          "للتقديم على خدمة استقدام معروفة، استقدام العمالة المنزلية المعينة من قبلكم والمختارة في الخارج، يتطلب عليك ارسال المعلومات التالية:اسم الخادمة المنزلية.عنوان البيت للخادمة المنزلية.رقم الجوال او الهاتف للخادمة المنزلية.رقم الجواز للخادمة المنزلية.يتطلب عليك رفع طلب التعاقد في موقع مساند مع تحديد الجنسية وجهة القدوم.كما يتطلب عليك دفع رسوم الاستقدام حسب الجنسية والمهنة.حسابي > لوحة التحكمطلبات العقود > التعاقد > معين بإسماختيار نوع العمل والمهنة والجنسيةبحث واختيار المكتب > ادخال البياناتتأكيد طلب ارسال التعاقد",
      },
      {
        title: "متطلبات نقل كفالة العمالة المنزلية (تنازل)",
        answer:
          "يمكن للمواطنين والمقيمين المسجلين والمفعلين في نظام أبشر الاستفادة من خدمات منصة أبشر لنقل خدمات العمالة المنزلية، ولتقديم طلب نقل كفالة أو تنازل العمالة المنزلية، يرجى زيارة تطبيق أبشرتسديد رسوم نقل الكفالة (٢٠٠٠ ريال).طلب نقل الكفالة إلى رقم هوية الكفيل الجديد.موافقة طلب نقل الكفالة من الكفيل الجديد.إصدار الجواز الجديد بعد نقل الكفالة.",
      },
    ],
  },

  {
    rowTwo: [
      {
        title: "نظام حماية أجور العمالة المنزلية",
        answer:
          "لتوثيق البطاقة البنكية في نظام حماية الأجور للعمالة المنزلية، يرجى زيارة موقع مساند www.musaned.com.staصدار بطاقة بنك مسبقة الدفع لرواتب العمالة المنزلية.توثيق بيانات العمالة المنزلية في نظام مساند.تحديث بيانات العقد ومعلومات بطاقة البنك مسبقة الدفع.يمكنك الحصول على بطاقة رواتب العمالة المنزلية الجديدة من خلال البنوك التالية ؛ البنك الأهلي، بنك الرياض، بنك الإنماء، مصرف الراجحيكما يمكنك الاستفادة من خدمة مساند أحد مبادرات وزارة الموارد البشرية والتنمية الاجتماعية في تطبيق STC Pay بتحويل رواتب العمالة المنزلية من محفظتك إلى المحفظة الخاصة بالعامل المنزلي او العاملة المنزلية في تطبيق STC Pay بسرعة وأمان من خلال أكثر من خيار لتحويل الراتب الشهري ؛ راتب كامل، أو راتب مخصوم، أو راتب مقدم، وبكذا تحفظ حقك وحقوقهم.stcpay.com.sa/ar/musaned",
      },
      {
        title: "تأمين استقدام العمالة المنزلية  ",
        answer:
          "يمكنك الاستفادة من خدمة التأمين على عقود العمالة المنزلية في موقع مساند وهي اختيارية غير إلزامية وذلك عن طريق الموافقة على الدخول في خدمة التأمين عند التعاقد من خلال حسابك في منصة مساندلمعرفة المزيد عن تأمين عقود العمالة المنزلية، نرجو التواصل معنا",
      },
      {
        title: "التأمين الطبي للعمالة المنزلية",
        answer:
          "يمكنك الحصول على التأمين الصحي للعمالة المنزلية والذي تقدمه شركات التأمين الصحي للعمالة المنزلية وذلك لحمايتهم من الحوادث الشخصية لا قدر الله ولتغطية النفقات الطبية، علما ان التأمين الصحي اختياريا وليس الزاميا على صاحب العمللمعرفة المزيد عن التأمين الصحي للعمالة المنزلية، نرجو التواصل معنا",
      },
      {
        title: "متطلبات إصدار الإقامة",
        answer:
          "لإصدار هوية مقيم (إقامة) للعمالة المنزلية القادمين إلى السعودية.تسجيل الدخول في منصة أبشر.اختيار الخدمات الإلكترونية، ثم خدمات المكفولين.اختيار إصدار الإقامة من القائمة.اختيار العامل المراد إصدار إقامة له.اختيار إصدار الإقامة.سيتم تسليم الإقامة من خلال خدمة توصيل الوثائق عبر البريد السعودي إلى عنوان واصل",
      },
    ],
  },
];

export const styles = {
  bmBurgerButton: {
    position: "relative",
    width: "50px",
    height: "50px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "44px",
    width: "44px",
    top: "10px",
    right: "24px",
  },
  bmCross: {
    background: colors.darkBlueColor,
    width: "5px",
    height: "30px",
    borderRadius: "1rem",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: "0",
    width: "90%",
  },
  bmMenu: {
    background: colors.whiteColor,
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
    boxShadow: "0 0 4px gray",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  bmItem: {
    display: "inline-block",
    textAlign: "left",
    color: colors.darkBlueColor,
    fontWeight: "500",
    textDecoration: "none",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

export const recruitmentReferenceData = [
  {
    name: "Hrsd",
    logo: Hrsd,
    url: "https://www.hrsd.gov.sa/",
  },
  {
    name: "Absher",
    logo: Absher,
    url: "https://absher.sa/",
  },
  {
    name: "Reference Ministry",
    logo: ReferenceMinistry,
    url: "https://www.moi.gov.sa",
  },
  {
    name: "Saudi ministry of foreign affairs",
    logo: Mofa,
    url: "https://www.mofa.gov.sa/",
  },
  {
    name: "Musand",
    logo: Musand,
    url: "https://tawtheeq.musaned.com.sa/",
  },
];

export const flipCardData = [
  {
    flag: <PhilippineFlag />,
    country: "الفلبين",
    backHeading: "عمالة فلبينية",
    period: "من 45 يوم الى 74 يوم",
    backSubHeading: "الاستقدام من الفلبين",
    contractCost: "16785 SAR",
    salary: "1500 SAR",
  },
  {
    flag: <SrilankaFlag />,
    country: "سريلانكا",
    backHeading: "عمالة سريلانكية",
    period: "من 30 يوم الى 60 يوم",
    backSubHeading: "الاستقدام من بنغلاديش",
    contractCost: "15000 SAR",
    salary: "1125 SAR",
  },
  {
    flag: <BangladeshFlag />,
    country: "بنغلاديش",
    backHeading: "عمالة سريلانكية",
    period: "من 45 يوم الى 90 يوم",
    backSubHeading: "الاستقدام من بنغلاديش",
    contractCost: "13511 SAR",
    salary: "1000 SAR لم يسبق لها العمل ، 1200 SAR سبق لها العمل",
  },
  {
    flag: <IndiaFlag />,
    country: "الهند",
    backHeading: "عمالة هندية",
    period: "من 45 يوم إلى 75 يوم",
    backSubHeading: "الاستقدام من الهند",
    contractCost: "5175 SAR",
    salary: "1500 SAR لم يسبق له العمل ، 1800 SAR سبق له العمل",
  },
  {
    flag: <EthiopiaFlag />,
    country: "اثيوبيا",
    backHeading: "عمالة اثيوبية",
    period: "من 45 يوم إلى 75 يوم",
    backSubHeading: "الاستقدام من اثيوبية",
    contractCost: "6861 SAR",
    salary: "1000 SAR لم يسبق لها العمل ، 1200 SAR سبق لها العمل",
  },
  {
    flag: <EgyptFlag />,
    country: "مصر",
    backHeading: "عمالة مصرية",
    period: "من 45 يوم إلى 75 يوم",
    backSubHeading: "الاستقدام من مصر",
    contractCost: "5175 SAR",
    salary: "1500 SAR لم يسبق له العمل ، 1800 SAR سبق له العمل",
  },
  {
    flag: <KenyaFlag />,
    country: "كينيا",
    backHeading: "عمالة كينية",
    period: "من 45 يوم إلى 75 يوم",
    backSubHeading: "الاستقدام من كينيا",
    contractCost: "10916 SAR",
    salary: "900 SAR لم يسبق لها العمل ، 1000 SAR سبق لها العمل",
  },
  {
    flag: <PakistanFlag />,
    country: "باكستان",
    backHeading: "عمالة باكستانية",
    period: " من 45 يوم إلى 75 يوم",
    backSubHeading: "الاستقدام من باكستان",
    contractCost: "5175 SAR",
    salary: "1500 SAR لم يسبق له العمل ، 1800 SAR سبق له العمل",
  },
  {
    flag: <YamenFlag />,
    country: "اليمن",
    backHeading: "عمالة يمنية",
    period: " من 45 يوم إلى 75 يوم",
    backSubHeading: "الاستقدام من اليمن",
    contractCost: "5175 SAR",
    salary: "1500 SAR لم يسبق له العمل ، 1800 SAR سبق له العمل",
  },
];

export const RecruitmentTimelineData = [
  {
    icon: <WebIcon />,
    Title: "سداد التأشيرة",
    description:
      "سداد رسوم تأشيرة العمالة المنزلية الخاصة بك عبر الخدمات الحكومية في البنك",
  },
  {
    icon: <DollarIcon />,
    Title: "إصدار التأشيرة",
    description: "طلب إصدار تأشيرة العمالة المنزلية الخاصة بك في برنامج مساند",
  },
  {
    icon: <EqualIcon />,
    Title: "السيرة الذاتية",
    description:
      "اختيار السيرة الذاتية للعمالة المنزلية عبر البحث في برنامج مساند",
  },
  {
    icon: <FeesIcon />,
    Title: "رسوم الإستقدام",
    description:
      "دفع رسوم استقدام العمالة المنزلية عبر التعاقد في برنامج مساند",
  },
  {
    icon: <AeroplaneIcon />,
    Title: "وصول العمالة",
    description: "وصول العمالة المنزلية من المطار المحلي للاستقبال في المكتب",
  },
];
