import React from "react";
import { SectionSpacer, SectionWrapper } from "../../components/Common";
import { styled } from "styled-components";
import {
  AboutCleaningImg,
  ExperienceIcon,
  RightArrow,
  colors,
} from "../../assets";
import { Footer } from "../Footer";
import { Link } from "react-router-dom";

const AboutCleaningWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;

  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;
const AboutCleaningImage = styled.img`
  width: 70%;
  /* width: 60%; */
  /* margin: auto; */
  @media screen and (max-width: 800px) {
    width: 85%;
    margin-top: 1rem;
  }
`;

const AboutCleaningImageContainer = styled.div`
  display: flex;
  position: relative;
`;
const AboutCleaningDescription = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 7rem;

  @media screen and (max-width: 1100px) {
    width: 100%;
    padding-left: 0rem;
  }
`;

const AboutCleaningHeader = styled.h5`
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.headColorBlue};
  display: flex;
  align-items: center;
  width: 67%;
  white-space: nowrap;

  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;

const AboutCleaningSubHeader = styled.h2`
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.darkBlueColor};

  @media screen and (max-width: 800px) {
    font-size: 32px;
  }

  @media screen and (max-width: 500px) {
    font-size: 25px;
    line-height: 150%;
  }
`;
const AboutCleaningData = styled.p`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.headColorBlue};

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const WorkStepsBorderBottom = styled.div`
  border-bottom: 3px solid ${colors.primaryBlueColor};
  width: 20%;
  margin: auto;
  margin-left: 1rem;
`;
const AboutUSButton = styled.button`
  background-color: ${colors.primaryBlueColor};
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 1rem 1.6rem;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  color: ${colors.whiteColor};
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  cursor: pointer;

  @media screen and (max-width: 500px) {
  }
`;

const Button = styled(Link)`
  text-decoration: none;
`;

const AboutUSCard = styled.div`
  width: 232px;
  height: 132px;
  top: 38px;
  left: 440px;
  border-radius: 6px;
  background: ${colors.primaryBlueColor};
  box-shadow: 0px 12px 32px 0px #0c0c0c30;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 500px) {
    /* width: 187px; */
    justify-content: flex-start;
    height: 85px;
    top: 50px;
    left: 172px;
    width: 50%;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 400px) {
    left: 150px;
    width: 45%;
  }
  @media screen and (max-width: 350px) {
    left: 125px;
    height: 70px;
  }
`;

const AboutUSCardContent = styled.div`
  text-align: start;
  font-family: "Rubik", sans-serif;
`;
const AboutUSCardContentWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.whiteColor};
  gap: 1rem;
`;
const AboutUSCardNumber = styled.h2``;
const AboutUSCardExp = styled.p`
  @media screen and (max-width: 400px) {
    font-size: 13px;
  }
`;
const ExperienceIconWrapper = styled.div`
  height: 70px;
  width: 70px;
  background-color: ${colors.whiteColor};
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 50px;
    width: 50px;
  }

  @media screen and (max-width: 400px) {
    height: 50px;
    width: 50px;
  }
`;
export const AboutCleaning = () => {
  return (
    <>
      <SectionWrapper>
        <SectionSpacer>
          <AboutCleaningWrapper>
            <AboutCleaningImageContainer>
              <AboutCleaningImage
                src={AboutCleaningImg}
                alt="cleaning"
              ></AboutCleaningImage>
              <AboutUSCard>
                <AboutUSCardContentWrapper>
                  <ExperienceIconWrapper>
                    <ExperienceIcon />
                  </ExperienceIconWrapper>
                  <AboutUSCardContent>
                    <AboutUSCardNumber>10+</AboutUSCardNumber>
                    <AboutUSCardExp>
                    سنوات من <br></br> الخبرة
                    </AboutUSCardExp>
                  </AboutUSCardContent>
                </AboutUSCardContentWrapper>
              </AboutUSCard>
            </AboutCleaningImageContainer>
            <AboutCleaningDescription>
              <AboutCleaningHeader>
                من نحن <WorkStepsBorderBottom />
              </AboutCleaningHeader>
              {/* <AboutCleaningSubHeader>
                You can depend on us to get a good services
              </AboutCleaningSubHeader> */}
              <AboutCleaningData>
                من نحن شركة مهند للاستقدام هي رائدة في مجال استقدام الأيدي
                العاملة، حيث تأسست في عام ٢٠٢١م بهدف تقديم خدمات استقدام مميزة
                وعالية الجودة. يقود هذه الشركة فريق من الخبراء ورجال الأعمال ذوي
                الخبرة الواسعة في هذا المجال، مما يجعلها تتميز بالاحترافية
                والمصداقية في كل خطوة تقدمها،تعد شركة مهند للاستقدام خيارًا
                مثاليًا للباحثين عن حلول توظيف موثوقة وفعالة.
              </AboutCleaningData>
              <Button to="/about">
                <AboutUSButton>
                  من نحن
                  <RightArrow />
                </AboutUSButton>
              </Button>
            </AboutCleaningDescription>
          </AboutCleaningWrapper>
        </SectionSpacer>
      </SectionWrapper>
    </>
  );
};
