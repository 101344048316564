import React from "react";
import { AboutCleaning, Footer, HowWeWork, Statistics } from "../../containers";
import { RecruitmentBanner } from "../../components";
import { AboutBanner, BannerB, BannerBG, colors } from "../../assets";
import { styled } from "styled-components";

const AboutPageContainer = styled.div`
  position: relative;
`;
const AboutPageBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${colors.contactbannerLightBLueColor};
`;
export const About = () => {
  return (
    <>
      <AboutPageContainer>
        <RecruitmentBanner img={BannerB} positions="top" />
        <AboutPageBackground></AboutPageBackground>
      </AboutPageContainer>

      <AboutCleaning />
      <HowWeWork />
      {/* <Statistics /> */}
      <Footer />
    </>
  );
};
