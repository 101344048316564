import React from "react";
import { SectionSpacer, SectionWrapper } from "../../components/Common";
import { styled } from "styled-components";
import {  BannerB,  RightArrow, TeamBG, TeamBGImage, colors } from "../../assets";

const TeamContainer = styled.section`
  height: 80vh;
  background: url(${BannerB});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media screen and (max-width: 500px) {
    background-position: center;
    background-size: cover;
  }
`;

const TeamWrapper = styled.section`
  background-color: ${colors.blackColorOpacity75};
  height: inherit;
  width: 100%;
  height: inherit;
  clip-path: polygon(52% 0, 100% 0, 100% 100%, 41% 100%);
  @media screen and (max-width: 800px) {
    clip-path: none;
  }
`;
const TeamBGLeft = styled.h2`
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;

  @media screen and (max-width: 800px) {
    font-size: 32px;
  }

  @media screen and (max-width: 500px) {
    font-size: 30px;
  }
`;

const TeamBGSpan = styled.span`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
`;

const TeamLeftWrapper = styled.div`
  width: 50%;
  margin: auto;
  color: ${colors.whiteColor};
  display: flex;
  flex-direction: column;
  padding-left: 7rem;
  gap: 1rem;
  @media screen and (max-width: 800px) {
    width: 100%;
    padding-left: 0;
  }
`;
const TeamRightWrapper = styled.div`
  width: 50%;
  margin: auto;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const TeamRightLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  margin: auto;
  height: inherit;
`;
const TeamButton = styled.a`
  background-color: ${colors.primaryBlueColor};
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 1rem 1.6rem;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: ${colors.whiteColor};
  /* width: 45%; */
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  /* justify-content: space-around; */
  @media screen and (max-width: 500px) {
  }
`;

export const WorkWithTeam = () => {
  return (
    <SectionSpacer>
      <TeamContainer>
        <TeamWrapper>
          {/* <SectionWrapper> */}
          <TeamRightLeftWrapper>
            <TeamRightWrapper></TeamRightWrapper>
            <TeamLeftWrapper>
              <TeamBGLeft>حاب تستفيد من خدماتنا؟</TeamBGLeft>
              {/* <TeamBGSpan>
                We love what we do and we do it with passion. We value the
                experimentation of the message and smart incentives.
              </TeamBGSpan> */}
              <TeamButton
                href="https://wa.me/966563363544"
                target="_blank"
                rel="noreferrer"
              >
                احصل على تسعيرة الان! <RightArrow />
              </TeamButton>
            </TeamLeftWrapper>
          </TeamRightLeftWrapper>
          {/* </SectionWrapper> */}
        </TeamWrapper>
      </TeamContainer>
    </SectionSpacer>
  );
};
