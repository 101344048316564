import React from "react";
import { styled } from "styled-components";
import {
  ArticleOne,
  ArticleTwo,
  FooterBE,
  FooterCalendar,
  FooterDate,
  FooterFB,
  FooterLoc,
  FooterSocial,
  FooterTele,
  FooterTwitter,
  Footercall,
  Footermail,
  colors,
} from "../../assets";
import { SectionSpacer, SectionWrapper } from "../../components/Common";

const FooterContainer = styled.div`
  width: 100%;
  margin: auto;
  background-color: ${colors.footerBlackColor};
  color: ${colors.whiteColor};
`;
const FooterWrapper = styled.div`
  display: flex;
  text-align: left;
  margin: auto;
  justify-content: space-between;

  @media screen and (max-width: 1100px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 3rem;
  }
`;
const FooterWeAre = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 150%;

  @media screen and (max-width: 1100px) {
    width: 50%;
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: auto;
  }
`;
const WeAreHeading = styled.h2``;
const WeArePara = styled.p`
  color: ${colors.footerTextColor};
  /* font-family: Nunito Sans; */
  font-size: 10px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
`;
const WeAreHeader = styled.h2``;
const WeAreSpan = styled.span`
  color: ${colors.footerTextColor};
  font-family: Rubik;
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
`;
const LatestArticle = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  @media screen and (max-width: 1100px) {
    width: 50%;
    margin-bottom: 4rem;
    align-items:center;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: auto;
    align-items: start;
  }
`;
const LatestArticleHeader = styled.h2``;
const LatestArticleBottom = styled.div`
  border-bottom: 3px solid ${colors.footerBorderBottomColor};
  width: 15%;
  margin-top: 10px;
`;
const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    align-items: flex-start;
  }
`;
const ArticleOneContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
const ArticleOneImg = styled.img``;
const ArticleOneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const ArticleOneHeading = styled.h2`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;
const ArticleTwoContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const ArticleDateWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const ArticleSpan = styled.span`
  font-family: Rubik;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
`;
const ArticleTwoImg = styled.img``;
const ArticleTwoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const ArticleTwoHeading = styled.h2`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const OfficialInfo = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 150%;
  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
const OfficialInfoHeader = styled.h2``;

const LocWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  color: ${colors.footerTextColor};
`;
const CallWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  color: ${colors.footerTextColor};
`;
const MailWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  color: ${colors.footerTextColor};
`;

const EmailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LocImg = styled.div``;
const CallImg = styled.div``;
const MailImg = styled.div``;

const LocSpan = styled.span`
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;
const CallSpan = styled.span`
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;
const MailSpan = styled.span`
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const NewsLetter = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
const NewsLetterHeader = styled.h2``;
const NewsLetterDetail = styled.span`
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.footerTextColor};
`;
const NewsLetterInput = styled.input`
  background-color: ${colors.footerInputColor};
  height: inherit;
  padding: 0.5px 70px 0.5px 20px;
  color: ${colors.whiteColor};
  border: none;
  font-family: "Rubik", sans-serif;

  &:focus {
    outline: none;
  }
  @media screen and (max-width: 1900px) {
    padding: 0.5px 40px 0.5px 20px;
  }
  @media screen and (max-width: 1750px) {
    padding: 0.5px 0px 0.5px 20px;
  }
  @media screen and (max-width: 1500px) {
    padding: 0.5px 0px 0.5px 20px;
  }
  @media screen and (max-width: 800px) {
    width: 40%;
  }
`;
const InputIcon = styled.div`
  width: 62px;
  height: inherit;
  background-color: ${colors.primaryBlueColor};
  color: ${colors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1500px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 45px;
`;

const SocialIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
`;

const FbImage = styled.div`
  cursor: pointer;
`;
const TwitterImage = styled.div`
  cursor: pointer;
`;
const SocialImage = styled.div`
  cursor: pointer;
`;
const BeImage = styled.div`
  cursor: pointer;
`;

const FooterBottom = styled.div`
  width: inherit;
  height: 60px;
  background: ${colors.blackColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FooterBottomSpan = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.footerTextColor};
`;

const AnchorComponent = styled.a`
  text-decoration: none;
  color: ${colors.footerTextColor};
`;
const WrapContainer = styled.div``;
export const Footer = () => {
  return (
    <>
      <FooterContainer>
        <SectionWrapper>
          <SectionSpacer>
            <FooterWrapper>
              <FooterWeAre>
                <WeAreHeading>من نحن</WeAreHeading>
                <WeArePara>
                  من نحن شركة مهند للاستقدام هي رائدة في مجال استقدام الأيدي
                  العاملة، حيث تأسست في عام ٢٠٢١م بهدف تقديم خدمات استقدام مميزة
                  وعالية الجودة. يقود هذه الشركة فريق من الخبراء ورجال الأعمال
                  ذوي الخبرة الواسعة في هذا المجال، مما يجعلها تتميز بالاحترافية
                  والمصداقية في كل خطوة تقدمها،تعد شركة مهند للاستقدام خيارًا
                  مثاليًا للباحثين عن حلول توظيف موثوقة وفعالة.
                </WeArePara>
              </FooterWeAre>
              <LatestArticle>
                <WeAreHeader>ساعات العمل</WeAreHeader>
                <WeAreSpan>
                الاحد - الخميس 9 صباحًا الى 9 مساءًا <br></br> السبت 4 مساءًا الى 9 مساءًا
                </WeAreSpan>
              </LatestArticle>
              <OfficialInfo>
                <OfficialInfoHeader>:تواصل معنا</OfficialInfoHeader>
                <LocWrapper>
                  <LocImg>
                    <FooterLoc />
                  </LocImg>
                  <LocSpan>
                    <AnchorComponent
                      href="https://maps.app.goo.gl/G24Ggj77JfAUWase9"
                      target="_blank"
                    >
                      3530 Khadijah Bint Khuwailid Rd, Aluraija Al Gharbiyah,
                      Riyadh 12966, Saudi Arabia
                    </AnchorComponent>
                  </LocSpan>
                </LocWrapper>
                <CallWrapper>
                  <CallImg>
                    <Footercall />
                  </CallImg>
                  <CallSpan>
                    <AnchorComponent href="tel:1-888-452-1505">
                      +(966)-563363544
                    </AnchorComponent>
                  </CallSpan>
                </CallWrapper>
                <MailWrapper>
                  <MailImg>
                    <Footermail />
                  </MailImg>
                  <EmailsWrapper>
                    <MailSpan>
                      <AnchorComponent href="mailto:muhannad.recruitment@gmail.com">
                        muhannad.recruitment@gmail.com
                      </AnchorComponent>
                    </MailSpan>
                    {/* <MailSpan>
                      <AnchorComponent href="mailto:info@mail.com">
                        info@mail.com
                      </AnchorComponent>
                    </MailSpan> */}
                  </EmailsWrapper>
                </MailWrapper>
              </OfficialInfo>
              {/* <NewsLetter>
                <NewsLetterHeader>Newsletter</NewsLetterHeader>
                <NewsLetterDetail>
                  Subscribe our newsletter to get our latest update & news
                </NewsLetterDetail>
                <InputWrapper>
                  <NewsLetterInput placeholder="Your mail address"></NewsLetterInput>
                  <InputIcon>
                    <FooterTele />
                  </InputIcon>
                </InputWrapper>
                <SocialIconWrapper>
                  <FbImage>
                    <FooterFB />
                  </FbImage>
                  <TwitterImage>
                    <FooterTwitter />
                  </TwitterImage>
                  <SocialImage>
                    <FooterSocial />
                  </SocialImage>
                  <BeImage>
                    <FooterBE />
                  </BeImage>
                </SocialIconWrapper>
              </NewsLetter> */}
            </FooterWrapper>
          </SectionSpacer>
        </SectionWrapper>
      </FooterContainer>
      <FooterBottom>
        <FooterBottomSpan>
          الحقوق محفوظة © شركة مهند للاستقدام 2024
        </FooterBottomSpan>
      </FooterBottom>
    </>
  );
};
