import "./App.css";
import { Route, Routes } from "react-router-dom";
import { About, Contact, Home, HowWeWork, Recruitment } from "./pages";
import { Navbar } from "./containers";
import { ArrowUp, WhatsApp } from "./assets";
import { colors } from "./assets/constants/colors";
import { keyframes, styled } from "styled-components";
import { useEffect, useState } from "react";

const heartbeat = keyframes`
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
`;

const WhatsAppAnchor = styled.a`
  right: 50px;
  bottom: 50px;
  animation: ${heartbeat} 1.5s infinite;
  transition: animation 0.3s ease;
  svg {
    width: 60px;
    height: 60px;
  }
`;

const FloatingSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 100000;
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: transparent;
  cursor: pointer;
  border: none;

  @media screen and (max-width: 500px) {
    bottom: 20px;
    right: 20px;
  }
`;

const ArrowButton = styled.div`
  border: 2px solid ${colors.primaryBlueColor};
  width: 50px;
  aspect-ratio: 1/1;
  display: flex;
  border-radius: 50%;
  background-color: ${colors.primaryBlueColor};
  align-items: center;
  justify-content: center;
  padding: auto;

  svg {
    width: 25px;
    height: 25px;
  }
  @media screen and (max-width: 500px) {
    width: 50px;
  }
`;

function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/recruitment" element={<Recruitment />} />
      </Routes>

      <FloatingSection onClick={scrollToTop} className="scroll-to-top">
        <WhatsAppAnchor
          href="https://wa.me/+966546116173?text=%22Hello%20there!%20I%20have%20a%20few%20queries%20that%20I%20would%20appreciate%20your%20assistance%20with.%20Could%20you%20please%20lend%20me%20a%20hand%3F%20Thank%20you%20in%20advance%20for%20your%20help!%22"
          target="_blank"
          rel="noreferrer"
        >
          <WhatsApp />
        </WhatsAppAnchor>
        {showButton && (
          // <Fade bottom>
          <ArrowButton className="arrow-button">
            <ArrowUp />
          </ArrowButton>
          // </Fade>
        )}
      </FloatingSection>
    </div>
  );
}

export default App;
