import { styled } from "styled-components";
import { BannerBG, VideoIcon, colors } from "../../assets";
import Typist from "react-typist";
import { useState, useEffect } from "react";
import { SectionWrapper } from "../../components/Common";

const BannerContainer = styled.section`
  height: 100vh;
  width: 100vw;
  background: url(${BannerBG});
  background-position: top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: ${colors.blackColor};
  text-align: left;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 800px) {
    background-position: 85%;
    background-size: 100% 100%;
    height: 70vh;
  }

  @media screen and (max-width: 500px) {
    background: #2f536b;
  }
`;
const BannerParagraph = styled.p`
  font-family: Rubik, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #6998aa;
  width: 40%;
  line-height: 150%;
  padding: 3rem 3rem;

  @media screen and (max-width: 1200px) {
    width: 70%;
  }
  @media screen and (max-width: 800px) {
    font-size: 18px;
    width: 70%;
    padding: 3rem 1rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
    padding: 3rem 0rem;
    width: 85%;
  }
`;
const TypistComponent = styled.p`
  width: 60%;
  line-height: 120%;
  font-weight: 700;
  color: ${colors.whiteColor};
  font-size: 3.8rem;
  text-align: right;

  @media screen and (max-width: 1200px) {
    width: 70%;
    font-size: 3rem;
  }
  @media screen and (max-width: 800px) {
    width: 80%;
    font-size: 2.5rem;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: 2.3rem;
  }
`;

const ServiceButton = styled.button`
  background-color: ${colors.whiteColor};
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 1rem 1.6rem;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  color: ${colors.blackColor};
  margin-left: 1rem;
  white-space: nowrap;
`;
const VideoButton = styled.button`
  background-color: ${colors.primaryBlueColor};
  outline: none;
  border: none;
  width: 57px;
  float: left;
  height: 57px;
  border-radius: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ImageText = styled.span`
  font-family: Rubik, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.whiteColor};
  position: absolute;
  right: 170px;
  bottom: 160px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 25%;
  @media screen and (max-width: 1200px) {
    width: 35%;
  }
  @media screen and (max-width: 800px) {
    width: 45%;
  }
  @media screen and (max-width: 600px) {
    width: 80%;
  }
`;
export const Banner = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(1);
  }, [count]);

  return (
    <>
      <BannerContainer>
        <SectionWrapper>
          <div>
            <div>
              {/* <span> شركة مهند للاستقدام</span> */}

              <TypistComponent
              // avgTypingDelay={50}
              // onTypingDone={() => setCount(0)}
              >
                شركة مهند للاستقدام
                <br></br>آفضل خدمات التوظيف-
                <br />
                عقود العمل الشهري-
              </TypistComponent>

              <BannerParagraph>
                شركة مهند للاستقدام تسعى جاهدة لتقديم أفضل وأسرع خدمة لعملائها,
                اكتشف الآن خدماتنا بكل يسر وسهولة لتجربة فريدة من نوعها في مجال
                عقود العمل الشهري
              </BannerParagraph>
            </div>
            {/* <ImageText>
        شركة مهند للاستقدام
        </ImageText> */}
            {/* <ButtonsWrapper>
              <VideoButton>
                <VideoIcon />
              </VideoButton>
              <ServiceButton>OUR SERVICES</ServiceButton>
            </ButtonsWrapper> */}
          </div>
        </SectionWrapper>
      </BannerContainer>
    </>
  );
};
